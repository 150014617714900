import axios from 'axios'
import { useHistory } from 'react-router-dom'

import {
  mapStateToProps,
  mapDispatchToProps
} from '../functions/mapStateToProps'

import { connect } from 'react-redux'

var JoinFromLink = (props) => {
  async function joinGame(username, token, refreshToken, UUID, password, pk) {
    const postBody = {
      username,
      token,
      refreshToken,
      UUID,
      password,
      pk
    }
    var res = await axios.post(process.env.REACT_APP_JOIN_ADDRESS, postBody)
    return res
  }

  function makeid(length) {
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  async function regsiterAccountAndJoinGame() {
    var res = ''
    var email = urlParams.get("email")
    do {
      var end = false
      var postBody = {
        username: email.split('@')[0] + '*' + makeid(4),
        password: makeid(8),
        email: email
      }
      res = await axios.post(process.env.REACT_APP_REGISTER_ADDRESS, postBody)
      if (res.status === 200) {
        end = true
        window.localStorage.setItem('username', res.data.username)
        window.localStorage.setItem('token', res.data.token)
        window.localStorage.setItem('refreshToken', res.data.refreshToken)
      }
    } while (!end)
    props.user(window.localStorage.getItem('username'), window.localStorage.getItem('token'), window.localStorage.getItem('refreshToken'))

    await joinGame(res.data.username, res.data.token, res.data.refreshToken, urlParams.get("UUID"), urlParams.get("pk"), urlParams.get("pk"))
  }

  const history = useHistory()
  var registered = false
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  if (urlParams.get("username")) {
    registered = true
    window.localStorage.setItem('username', urlParams.get("username"))
    window.localStorage.setItem('token', urlParams.get("token"))
    window.localStorage.setItem('refreshToken', urlParams.get("refreshToken"))
  }

  if (registered) {
    return (
      <div className='mt-5 text-center'>
        Hello {urlParams.get("username")}!
        Click button below to join the grid. <br></ br>
        <button id={urlParams.get("UUID")} className="my-4 btn own-btn grey-btn" onClick={async (e) => {
          await joinGame(urlParams.get("username"), urlParams.get("token"), urlParams.get("refreshToken"), urlParams.get("UUID"), urlParams.get("pk"), urlParams.get("pk"))
          history.push('game/' + urlParams.get("UUID"))
        }}>Join</button>
      </div >
    )

  }

  return (
    <div className='mt-5 text-center'>
      Hello!
      It seems that you don't have account on our platform yet. Click button below to generate it and join the grid. Your account details will be sent to you by email. <br></ br>
      <button id={urlParams.get("UUID")} className="my-4 btn own-btn grey-btn" onClick={async (e) => {
        await regsiterAccountAndJoinGame()
        history.push('game/' + urlParams.get("UUID"))
      }}>Join</button>
    </div >
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(JoinFromLink)
