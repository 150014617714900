import React, {
  Component
} from 'react'

import {
  mapStateToProps,
  mapDispatchToProps
} from '../functions/mapStateToProps'

import { connect } from 'react-redux'

import { Redirect } from "react-router-dom"

var that, firstRun, justEntered

class MyProfile extends Component {
  constructor(props) {
    super(props)
    that = this
    this.state = {
      gamesOngoing: <div></div>,
      gamesPast: <div></div>,
      userPage: false
    }
    firstRun = true
    justEntered = true
  }

  //get user past and ongoing games ID
  componentDidMount() {
    that.props.getUserGames(window.localStorage.getItem('username'), window.localStorage.getItem('token'), window.localStorage.getItem('refreshToken'))
    var account = window.location.pathname.split('/')
    if (window.localStorage.getItem('username') === account[account.length - 1]) {
      this.setState({
        userPage: true
      })
    }
  }

  checkResponse() {
    if (that.props.game.response === 'Games returned') {
      if (firstRun) {
        var ongoingJSX = []
        var pastJSX = []

        for (let i = 0; i < that.props.game.playing.length; i++) {
          ongoingJSX.push(
            <div className='d-flex row bottom-line mb-3'>
              <p className="small-top-margin col-sm-4 text-center"><b>{that.props.game.playing[i].UUID}</b></p>
              <p className="small-top-margin col-sm-4 text-center"><b>{that.props.game.playing[i].topic}</b></p>
              <div className='col-sm-4 text-center'>
                <button id={that.props.game.playing[i].UUID} className="own-btn btn grey-btn mb-3" onClick={this.joinGame}>Rejoin</button>
              </div>
            </div>
          )
        }

        for (let i = 0; i < that.props.game.finished.length; i++) {
          pastJSX.push(
            <div className='d-flex row bottom-line mb-3'>
              <p className="small-top-margin col-sm-4 text-center"><b>{that.props.game.finished[i].UUID}</b></p>
              <p className="small-top-margin col-sm-4 text-center"><b>{that.props.game.finished[i].topic}</b></p>
              <div className='col-sm-4 text-center'>
                <button id={that.props.game.finished[i].UUID} className="own-btn btn grey-btn mb-3" onClick={this.joinGame}>Watch</button>
              </div>
            </div>
          )
        }

        this.setState({
          gamesOngoing: ongoingJSX,
          gamesPast: pastJSX
        })
        firstRun = false
      }
    }

    if (that.props.game.UUID && !justEntered) {
      justEntered = true
      return <Redirect to={'/game/' + that.props.game.UUID} />
    }
  }

  joinGame(e) {
    justEntered = false
    that.props.join(window.localStorage.getItem("username"), window.localStorage.getItem("token"), window.localStorage.getItem("refreshToken"), e.target.id, '')
  }

  goBack() {
    that.props.history.push("/")
  }

  render() {
    if (this.state.userPage) {
      return (
        <div>
          {this.checkResponse()}
          <div className='text-center'>
            <img src='/img/logo.svg' className='logo-big' alt='logo' />
          </div>
          <div id='ongoing-games text-center'>
            <p className='text-center w-50 mx-auto fix-width-mobile'>
              Here you can rejoin to ongoing grid
            </p>
            <div className='d-flex row bottom-line mb-3'>
              <p className="small-top-margin col-sm-4 text-center"><b>Grid ID</b></p>
              <p className="small-top-margin col-sm-4 text-center"><b>Current Topic</b></p>
              <div className='col-sm-4'>
              </div>
            </div>
            {this.state.gamesOngoing}
          </div>
          <div id='past-games text-center'>
            <p className='text-center w-50 mx-auto fix-width-mobile'>
              Or watch the past boards in grids you participated
            </p>
            <div className='d-flex row bottom-line mb-3'>
              <p className="small-top-margin col-sm-4 text-center"><b>Grid ID</b></p>
              <p className="small-top-margin col-sm-4 text-center"><b>Topic</b></p>
              <div className='col-sm-4'>
              </div>
            </div>
            {this.state.gamesPast}
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className='text-center'>
            <img src='/img/logo.png' className='logo-big' alt='logo' />
          </div>
          <div className='ongoing-games text-center'>
            <p className='text-center w-50 mx-auto fix-width-mobile'>
              We are sorry but you cannot view that page. It is not yours. Let us take you back.
            </p>
            <button className='btn grey-btn' onClick={that.goBack}>To Homepage</button>
          </div>
        </div >

      )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile)
