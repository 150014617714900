var PageNotFound = () => {
    return (
        <div className='mt-5 text-center'>
            <img src='/img/logo.png' alt="Logo Main" className='logo-big my-5' />
            <h2 className='text-center mb-4 mx-3'>Ooops! You weren't supposed to see this</h2>
            <div className='text-center'>
                Please, use the navigation above to find your way back.
            </div>
        </div>
    )
}

export default PageNotFound