import axios from 'axios'

export const INVITE_USER_REQUEST = 'invite_user_request'
export const INVITE_USER_SUCCESS = 'invite_user_success'
export const INVITE_USER_ERROR = 'invite_user_error'

export function inviteUser(email, roomNumber, username, token, refreshToken) {
    const postBody = {
        email,
        roomNumber,
        username,
        token,
        refreshToken
    }

    return async function (dispatch) {
        dispatch(checkIfValidRequest())
        axios.post(process.env.REACT_APP_INVITE_ADDRESS, postBody)
            .then((res) => {
                dispatch(checkIfValidSuccess(res))
            }, (e) => {
                dispatch(checkIfValidError(e))
            })
    }

    function checkIfValidRequest() {
        return {
            type: INVITE_USER_REQUEST
        }
    }

    function checkIfValidSuccess(res) {
        return {
            type: INVITE_USER_SUCCESS,
            payload: res
        }
    }

    function checkIfValidError(e) {
        return {
            type: INVITE_USER_ERROR,
            payload: { error: e }
        }
    }
}