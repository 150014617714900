import React, {
  Component
} from 'react'

import {
  mapStateToProps,
  mapDispatchToProps
} from '../functions/mapStateToProps'

import { connect } from 'react-redux'

import { Redirect } from "react-router-dom"

import { socket } from './App'

import { displayMessage, hideMessage } from '../functions/utils'

var that, justEntered

class Host extends Component {
  constructor(props) {
    super(props)
    that = this
  }

  componentDidMount() {
    justEntered = true
  }

  hostGame() {
    hideMessage()
    justEntered = false
    var pass1 = document.getElementById('pass1')
    var pass2 = document.getElementById('pass2')
    if (pass1.value === pass2.value) {
      if (pass1.value.trim() !== "") {
        that.props.host(
          window.localStorage.getItem("username"),
          window.localStorage.getItem("token"),
          window.localStorage.getItem("refreshToken"),
          pass1.value)
      } else {
        displayMessage('Password can not be empty')
      }
    } else {
      displayMessage('Passwords are not identical')
    }
  }

  componentWillUnmount() {
    hideMessage()
  }

  hostRedirection() {
    if (that.props.game.error) {
      displayMessage(that.props.game.error)
    }

    if (that.props.game.UUID && !justEntered) {
      socket.emit('newGameCreated')
      justEntered = true
      return <Redirect to={'/game/' + that.props.game.UUID} />
    }
  }

  changePassVisibility() {
    var passCheck = document.getElementById('set-password')
    if (passCheck) {
      var passContainer = document.getElementById('show-password-input')
      if (passContainer) {
        if (passCheck.checked) {
          passContainer.classList.remove('d-none')
        } else {
          passContainer.classList.add('d-none')
        }
      }
    }
  }

  render() {
    return (
      <div className='mx-3'>
        {that.hostRedirection()}
        <div id="success" className='success max-width-400 mx-auto'></div>
        <div className='mt-5 text-center'>
          <img src='/img/logo.svg' className='logo-big mb-4' alt="Logo" />
          <p className='w-50 mx-auto fix-width-mobile text-left'>
            Here you can host a new grid. You just need to follow these steps:
          </p>
          <p className='w-50 mx-auto fix-width-mobile text-left'>
            1. Create a password for your grid that you can share with your team and click Host to start.
          </p>
          <p className='w-50 mx-auto fix-width-mobile text-left'>
            2. You will be a host which means that you are the only person who can add and edit the category, the topic and top keywords.<br />
            The rest of the team will be able to collaborate with you on the full grid.
          </p>
          <p className='w-50 mx-auto fix-width-mobile text-left'>
            3.You can either input all the words in together with your team or click 'Get Assosiations' and our AI will automatically fill out the grid for you using the Key Words. All the words can still be edited if the suggested ones don't fit you.

          </p>
          <p className='w-50 mx-auto fix-width-mobile text-left'>
            4. Once the grid is fully completed everyone can roll their dice to see which words have been selected for creating their ideas.

          </p>
          <p className='w-50 mx-auto fix-width-mobile text-left'>
            5. After this, if you need some suggestions or ideas for the category, you can press "Generate AI Ideas" so you can get ideas generated by Chat GPT based on the category, topic and keywords selected by the dice.


          </p>
        </div>
        <div className='text-center'>
          <form className='password-inputs my-4' id='show-password-input'>
            <label for='pass1'>Grid Password</label><br />
            <input id='pass1' name="pass1" class="own-input max-width-400" type="password" autoComplete="off"/><br /><br />
            <label for='pass2'>Repeat Password</label><br />
            <input id='pass2' name="pass2" class="own-input max-width-400" type="password" autoComplete="off"/>
          </form>
        </div>
        <div className='text-center my-4'>
          <button className='btn grey-btn' onClick={that.hostGame}>Host</button>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Host)
