import { useState } from "react";

import {
  mapStateToProps,
  mapDispatchToProps
} from '../functions/mapStateToProps'

import { connect } from 'react-redux'

var ResetPasswordForm = (props) => {
  const [password, setPassword] = useState("")
  const [checkPassword, setCheckPassword] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  if (props.passwordResetState.error) {
    switch (props.passwordResetState.error) {
      case '401':
        if (errorMessage !== "Your account is blocked. You cannot change your password.") {
          setErrorMessage("Your account is blocked. You cannot change your password.")
        }
        break;
      case '402':
        if (errorMessage !== "Token has already expired. Please start whole reset password process from beggining.") {
          setErrorMessage("Token has already expired. Please start whole reset password process from beggining.")
        }
        break;
      case '404':
        if (errorMessage !== "This account is not present in our database. Please register on sign in page.") {
          setErrorMessage("This account is not present in our database. Please register on sign in page.")
        }
        break;
      case '405':
        if (errorMessage !== "Bad request. Please contact our support.") {
          setErrorMessage("Bad request. Please contact our support.")
        }
        break;
      case '500':
        if (errorMessage !== "There was internal error. We are sorry for inconvinience. Please, try again later.") {
          setErrorMessage("There was internal error. We are sorry for inconvinience. Please, try again later.")
        }
        break;
      default:
        if (errorMessage !== "There was internal error. We are sorry for inconvinience. Please, try again later.") {
          setErrorMessage("There was internal error. We are sorry for inconvinience. Please, try again later.")
        }
        break;
    }
  }

  if (props.passwordResetState.status) {
    if (errorMessage !== "Your password was changed. You can now log in to your account in sign in page.") {
      setErrorMessage("Your password was changed. You can now log in to your account in sign in page.")
    }
  }

  if (props.passwordResetState === 'Changing password') {
    if (errorMessage !== "Request is being fullfiled") {
      setErrorMessage("Request is being fullfiled")
    }
  }

  return (
    <div className='mt-5 text-center'>
      <div className="my-4">
        {errorMessage}
      </div>
      <div className="my-4">
        Fill out this form to change your password.
      </div>
      <div className='input-width mx-auto mb-3'>
        <input className='own-input' placeholder='New Password' type="password" onChange={(e) => setPassword(e.target.value)} value={password} />
      </div>
      <div className='input-width mx-auto mb-3'>
        <input className='own-input' placeholder='Repeat Password' type="password" onChange={(e) => setCheckPassword(e.target.value)} value={checkPassword} />
      </div>
      <button className="own-btn btn grey-btn" onClick={() => {
        if (password.trim() !== '') {
          if (password === checkPassword) {
            setErrorMessage("Request is being fullfiled")
            sendChangePasswordRequest(props, password)
          } else {
            setErrorMessage("Passwords do not match")
          }
          return
        }
        setErrorMessage("Password cannot be empty")
      }}>Change Password</button>
    </div>
  )
}

var sendChangePasswordRequest = (props, password) => {
  props.setNewPassword(props.username, props.token, password)
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm)
