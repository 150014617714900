import React, {
    Component
} from 'react'

import {
    mapStateToProps,
    mapDispatchToProps
} from '../functions/mapStateToProps'

import { connect } from 'react-redux'

var that

class Menu extends Component {
    constructor(props) {
        super(props)
        that = this
    }

    componentDidMount() {
        this.props.user(window.localStorage.getItem('username'), window.localStorage.getItem('token'), window.localStorage.getItem('refreshToken'))
    }

    async checkOnRefresh() {
        await this.props.user(window.localStorage.getItem('username'), window.localStorage.getItem('token'), window.localStorage.getItem('refreshToken'))

    }
    hostGame(e) {
        e.preventDefault()
        that.props.history.push("/host")
    }

    // joinGame(e) {
    //     e.preventDefault()
    //     that.props.history.push("/join")
    // }

    profile(e) {
        e.preventDefault()
        that.props.history.push('/profile/' + window.localStorage.getItem("username"))
    }

    signIn(e) {
        e.preventDefault()
        if (e.target.innerText === "Sign Out") {
            that.logoutUser()
            that.props.loginDelete()
            that.props.registerDelete()
            that.props.user(window.localStorage.getItem('username'), window.localStorage.getItem('token'), window.localStorage.getItem('refreshToken'))
            that.props.history.push("/logout")
        } else {
            that.props.history.push("/")
        }
    }

    loggedInUser() {
        var hiddenElements = document.getElementsByClassName("show-log-in")
        if (hiddenElements) {
            for (var i = 0; i < hiddenElements.length; i++) {
                hiddenElements[i].classList.remove("hidden")
            }
        }
        var username = document.getElementById("your-username")
        if (username) {
            username.innerText = window.localStorage.getItem("username")
        }

        var logoutButton = document.getElementById("login-logout")
        if (logoutButton) {
            logoutButton.innerText = "Sign Out"
        }

        var login = document.getElementById("login")
        var register = document.getElementById("register")
        if (login) {
            login.classList.add("hidden")
        }

        if (register) {
            register.classList.add("hidden")
        }

        var msgNotLogged = document.getElementById("msg-not-logged")
        if (msgNotLogged) {
            msgNotLogged.classList.add("hidden")
        }

        // var el = document.getElementById('detection')
        // if (el) {
        //     el.textContent = 'You are logged in. To host a game go to Host Game screen. To join an ongoing game please choose Join Game instead.'
        // }
    }

    logoutUser() {
        that.hideUserUI()
    }

    hideUserUI() {
        var hiddenElements = document.getElementsByClassName("show-log-in")
        if (hiddenElements) {
            for (var i = 0; i < hiddenElements.length; i++) {
                hiddenElements[i].classList.add("hidden")
            }
        }

        var username = document.getElementById("your-username")
        if (username) {
            username.innerText = ""
        }

        var logoutButton = document.getElementById("login-logout")
        if (logoutButton) {
            logoutButton.innerText = "Sign In"
        }

        // var el = document.getElementById('detection')
        // if (el) {
        //     el.textContent = 'You are not logged in. To host or join the game please log in or register new account.'
        // }

        that.clearStorage()
    }

    clearStorage() {
        window.localStorage.removeItem('username')
        window.localStorage.removeItem('email')
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('refreshToken')
    }

    checkResponse() {

        console.log("checkResponse")
        if (that.props.userState.response === "User is not authorized") {
            that.logoutUser()
        }
        if (that.props.userState.response === "User is authorized") {
            that.loggedInUser()
            return
        }
        if (that.props.loging.username && that.props.loging.token && that.props.loging.refreshToken) {
            window.localStorage.setItem("username", that.props.loging.username)
            window.localStorage.setItem("token", that.props.loging.token)
            window.localStorage.setItem("refreshToken", that.props.loging.refreshToken)
            that.loggedInUser()
            let success = document.getElementById('success')
            if (success) {
                success.classList.add('small-padding')
                success.textContent = 'You have logged in successfully. \r\n Host or Join a Grid and start creating new, unique ideas!'
            }
            return
        }
        if (that.props.registering.username && that.props.registering.token && that.props.registering.refreshToken) {
            window.localStorage.setItem("username", that.props.registering.username)
            window.localStorage.setItem("token", that.props.registering.token)
            window.localStorage.setItem("refreshToken", that.props.registering.refreshToken)
            that.loggedInUser()
            let success = document.getElementById('success')
            if (success) {
                success.classList.add('small-padding')
                success.textContent = "Account registered. \r\n To host grid use menu on top. To join grid click on logo and fill up form on following page."
            }
            return
        }

        if (window.localStorage.getItem("tried") === "login") {
            if (that.props.loging.error) {
                that.hideUserUI()
                let error = document.getElementById('error')
                if (error) {
                    error.classList.add('small-padding')
                    error.textContent = that.props.loging.error
                }
            }
            return
        } else if (window.localStorage.getItem("tried") === "register") {
            if (that.props.registering.error) {
                that.hideUserUI()
                let error = document.getElementById('error')
                if (error) {
                    error.classList.add('small-padding')
                    error.textContent = that.props.registering.error
                }
            }
            return
        } else {
            if (that.props.userState.error) {
                that.hideUserUI()
            }
            return
        }
    }

    render() {
        return (
            <nav className="navbar navbar-expand-xl">
                <div>
                    {/* {this.checkOnRefresh()} */}
                    {this.checkResponse()}
                </div>
                <div className="container-fluid my-4">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"><img src="/img/hamburger.png" alt="Hamburger Icon" className='hamburger' /></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-xl-0">
                            <li className="nav-item hidden show-log-in">
                                {/* eslint-disable-next-line*/}
                                <a className="nav-link" href="/">Join</a>
                            </li>
                            <li className="nav-item hidden show-log-in">
                                {/* eslint-disable-next-line */}
                                <a id="join-game" className="nav-link" href="#" onClick={this.hostGame}>Host Grid</a>
                            </li>
                            {/* <li className="nav-item hidden show-log-in">
                                <a id="join-game" className="nav-link" href="#" onClick={this.joinGame}>Join Game</a>
                            </li> */}
                            <li className="nav-item hidden show-log-in">
                                {/* eslint-disable-next-line*/}
                                <a id="your-username" className="nav-link" href="#" onClick={this.profile}></a>
                            </li>
                            <li className="nav-item">
                                {/* eslint-disable-next-line*/}
                                <a id="login-logout" className="nav-link" href="#" onClick={this.signIn}>Sign In</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Menu)
