import React, {
    Component
} from 'react'

import { socket } from './App'

var that, id

class Chat extends Component {
    constructor(props) {
        super(props)
        this.state = {
            players: [],
            messages: [],
            colors: []
        }
        that = this
    }

    componentDidMount() {
        var path = window.location.pathname
        id = path.split('/')
        if (id[2]) {
            socket.emit('getPlayers', {
                id: id[2],
                username: window.localStorage.getItem("username"),
                token: window.localStorage.getItem("token"),
                refreshToken: window.localStorage.getItem("refreshToken")
            })
            socket.emit('getMessages', {
                id: id[2],
                username: window.localStorage.getItem("username"),
                token: window.localStorage.getItem("token"),
                refreshToken: window.localStorage.getItem("refreshToken")
            })

        }
        socket.on('message', (props) => {
            var messages = that.state.messages
            var pl = document.getElementById('pl')
            var children = pl.children
            for (let i = 0; i < children.length; i++) {
                if (children[i].id === props.username) {
                    if (props.username === window.localStorage.getItem("username")) {
                        messages.push(<div className="your-message">
                            <div>
                                <div className="username" style={{ color: children[i].style.backgroundColor }}>{props.username}</div>
                                <div className="date">{props.date}</div>
                            </div>
                            <div className="message">{props.message}</div>
                        </div>)
                    } else {
                        messages.push(<div className="player-message">
                            <div>
                                <div className="username" style={{ color: children[i].style.backgroundColor }}>{props.username}</div>
                                <div className="date">{props.date}</div>
                            </div>
                            <div className="message">{props.message}</div>
                        </div>)
                    }
                }
            }

            that.setState({
                messages: messages
            })

            that.scrollChatToBottom()
        })

        socket.on('playerJoined', (players) => {
            var playersJSX = []
            for (var i = 0; i < players.players.length; i++) {
                playersJSX.push(<div id={players.players[i]} className='player' style={{ backgroundColor: players.colors[i] }}>{players.players[i]}</div>)
            }

            that.setState({
                players: playersJSX,
                colors: players.colors
            })
        })

        socket.on('playersSent', (players) => {
            var playersJSX = []
            for (var i = 0; i < players.players.length; i++) {
                playersJSX.push(<div id={players.players[i]} className='player' style={{ backgroundColor: players.colors[i] }}>{players.players[i]}</div>)
            }

            that.setState({
                players: playersJSX,
                colors: players.colors
            })
        })

        socket.on('messagesSent', (props) => {
            var messages = that.state.messages
            var pl = document.getElementById('pl')
            var children = pl.children
            for (let i = 0; i < props.length; i++) {
                for (let j = 0; j < children.length; j++) {
                    if (children[j].id === props[i].username) {
                        if (props[i].username === window.localStorage.getItem("username")) {
                            messages.push(<div className="your-message">
                                <div>
                                    <div className="username" style={{ color: children[j].style.backgroundColor }}>{props[i].username}</div>
                                    <div className="date">{props[i].date}</div>
                                </div>
                                <div className="message">{props[i].message}</div>
                            </div>)
                        } else {
                            messages.push(<div className="player-message">
                                <div>
                                    <div className="username" style={{ color: children[j].style.backgroundColor }}>{props[i].username}</div>
                                    <div className="date">{props[i].date}</div>
                                </div>
                                <div className="message">{props[i].message}</div>
                            </div>)
                        }
                    }
                }
            }

            that.setState({
                messages: messages
            })

            that.scrollChatToBottom()
        })
    } github

    scrollChatToBottom() {
        var objDiv = document.getElementById("messages")
        if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight
        }
    }

    sendMessage(e) {
        e.stopPropagation()
        var el = document.getElementById('message')
        if (el) {
            if (id[2]) {
                socket.emit('msg', ({
                    message: el.value,
                    room: id[2],
                    username: window.localStorage.getItem('username'),
                    token: window.localStorage.getItem('token'),
                    refreshToken: window.localStorage.getItem('refreshToken')
                }))
                el.value = ""
            }
        }
    }

    chatActions() {
        var chat = document.getElementById('chat')
        if (chat) {
            var el = document.getElementById('chat-tilt')
            if (el) {
                el.classList.add('hidden')
            }
            if (chat.classList.contains('hidden')) {
                chat.classList.remove('hidden')
                let element = document.getElementById('chat-arr')
                if (element) {
                    element.src = '/img/arrow-down.png'
                }
                that.scrollChatToBottom()
            } else {
                chat.classList.add('hidden')
                let element = document.getElementById('chat-arr')
                if (element) {
                    element.src = '/img/arrow-top.png'
                }
            }
        }
    }

    clickEnter(){
        var input = document.getElementById("message");
    
        input.addEventListener("keypress", function(event) {
          if (event.key === "Enter") {
            event.preventDefault();
            document.getElementById("send-chat-message").click();
          }
        });
      }

    removeTilt() {
        var el = document.getElementById('chat-tilt')
        if (el) {
            el.classList.add('hidden')
        }
        /* This part is to send message with Enter*/ 
        var input = document.getElementById("message");
    
        input.addEventListener("keypress", function(event) {
          if (event.key === "Enter") {
            event.preventDefault();
            document.getElementById("send-chat-message").click();
          }
        });
    }

    render() {
        return (
            <div className='chat position-fixed ms-4 bottom left'>
                <div className='chat-title clickable white-color' onClick={this.chatActions}>
                    <img
                        src="/img/arrow-top.png"
                        className="to-chat position-absolute position-arrow"
                        id="chat-arr"
                        alt="Logo" />
                    <h6 className='white-color'>Idea Sharing</h6>
                </div>
                {/* eslint-disable-next-line */}
                <h3 id='chat-tilt' className='chat-tilt hidden'></h3>
                <div id="chat" className='chat-hidden hidden'>
                    <div>
                        <div id="pl" className='players bottom-line d-flex'>
                            {this.state.players}
                        </div>
                        <div id='messages' className='messages bottom-line'>
                            {this.state.messages}
                        </div>
                    </div>
                    <div className='d-flex msg-input'>
                        <input
                            id="message"
                            className='message-input own-input'
                            type="text"
                            onClick={this.removeTilt} />
                        <button id="send-chat-message" className='btn grey-btn' onClick={this.sendMessage}>Send</button>
                    </div>
                </div>
            </div>
        )
    }
}

export { Chat }
