import {
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS
} from '../actions/reset-password-actions'

export default function resetPasswordReducer(state = {}, action) {
    switch (action.type) {
        case RESET_PASSWORD_REQUEST:
            return "Requesting password reset"
        case RESET_PASSWORD_SUCCESS:
            return (action.payload.data)
        case RESET_PASSWORD_ERROR:
            let resetPasswordPayload = action.payload.error.toString().split(' ')
            return { error: resetPasswordPayload[resetPasswordPayload.length - 1] }
        case CHANGE_PASSWORD_REQUEST:
            return "Changing password"
        case CHANGE_PASSWORD_SUCCESS:
            return (action.payload.data)
        case CHANGE_PASSWORD_ERROR:
            let changePasswordPayload = action.payload.error.toString().split(' ')
            return { error: changePasswordPayload[changePasswordPayload.length - 1] }
        default:
            return state
    }
}