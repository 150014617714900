import {
    user
} from '../actions/user-actions'

import {
    login,
    loginDelete
} from '../actions/login-actions'

import {
    register,
    registerDelete
} from '../actions/register-actions'

import {
    host,
    join,
    checkPermisions,
    getUserGames
} from '../actions/game-actions'

import {
    reset,
    setNewPassword
} from '../actions/reset-password-actions'

import {
    inviteUser
} from '../actions/invite-actions'

import {
    generateImage
} from '../actions/image-actions'

export function mapStateToProps(state) {
    return {
        userState: state.user,
        loging: state.login,
        registering: state.register,
        game: state.game,
        passwordResetState: state.passwordReset,
        inviteUserState: state.inviteUser,
        generatingImage: state.generateImage
    }
}

export const mapDispatchToProps = { user, login, loginDelete, register, registerDelete, host, join, checkPermisions, getUserGames, reset, setNewPassword, inviteUser, generateImage }