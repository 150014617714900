import axios from 'axios'

export const CHECK_ACCOUNT_REQUEST = 'check_account_request'
export const CHECK_ACCOUNT_SUCCESS = 'check_account_success'
export const CHECK_ACCOUNT_ERROR = 'check_account_error'

export function user(username, token, refreshToken) {
    const postBody = {
        username,
        token,
        refreshToken
    }

    return async function (dispatch) {
        dispatch(checkIfValidRequest())
        axios.post(process.env.REACT_APP_VALID_ADDRESS, postBody)
            .then((res) => {
                dispatch(checkIfValidSuccess(res))
            }, (e) => {
                dispatch(checkIfValidError(e))
            })
    }

    function checkIfValidRequest() {
        return {
            type: CHECK_ACCOUNT_REQUEST
        }
    }

    function checkIfValidSuccess(res) {
        return {
            type: CHECK_ACCOUNT_SUCCESS,
            payload: res
        }
    }

    function checkIfValidError(e) {
        return {
            type: CHECK_ACCOUNT_ERROR,
            payload: { error: e }
        }
    }
}