import axios from 'axios'

export const HOST_BOARD_REQUEST = 'host_board_request'
export const HOST_BOARD_SUCCESS = 'host_board_success'
export const HOST_BOARD_ERROR = 'host_board_error'
export const JOIN_BOARD_REQUEST = 'join_board_request'
export const JOIN_BOARD_SUCCESS = 'join_board_success'
export const JOIN_BOARD_ERROR = 'join_board_error'
export const PERMISIONS_REQUEST = 'permisions_request'
export const PERMISIONS_SUCCESS = 'permisions_success'
export const PERMISIONS_ERROR = 'permisions_error'
export const USER_GAMES_REQUEST = 'user_games_request'
export const USER_GAMES_SUCCESS = 'user_games_success'
export const USER_GAMES_ERROR = 'user_games_error'

export function host(username, token, refreshToken, password) {
    const postBody = {
        username,
        token,
        refreshToken,
        password
    }

    return async function (dispatch) {
        dispatch(hostRequest())
        axios.post(process.env.REACT_APP_HOST_ADDRESS, postBody)
            .then((res) => {
                dispatch(hostSuccess(res))
            }, (e) => {
                dispatch(hostError(e))
            })
    }

    function hostRequest() {
        return {
            type: HOST_BOARD_REQUEST
        }
    }

    function hostSuccess(res) {
        return {
            type: HOST_BOARD_SUCCESS,
            payload: res
        }
    }

    function hostError(e) {
        return {
            type: HOST_BOARD_ERROR,
            payload: { error: e }
        }
    }
}

export function join(username, token, refreshToken, UUID, password) {
    const postBody = {
        username,
        token,
        refreshToken,
        UUID,
        password
    }

    return async function (dispatch) {
        dispatch(joinRequest())
        axios.post(process.env.REACT_APP_JOIN_ADDRESS, postBody)
            .then((res) => {
                dispatch(joinSuccess(res))
            }, (e) => {
                dispatch(joinError(e))
            })
    }

    function joinRequest() {
        return {
            type: JOIN_BOARD_REQUEST
        }
    }

    function joinSuccess(res) {
        return {
            type: JOIN_BOARD_SUCCESS,
            payload: res
        }
    }

    function joinError(e) {
        return {
            type: JOIN_BOARD_ERROR,
            payload: { error: e }
        }
    }
}

export function checkPermisions(username, token, refreshToken, UUID) {
    const postBody = {
        username,
        token,
        refreshToken,
        UUID
    }

    return async function (dispatch) {
        dispatch(permisionsRequest())
        axios.post(process.env.REACT_APP_PERMISIONS_ADDRESS, postBody)
            .then((res) => {
                dispatch(permisionsSuccess(res))
            }, (e) => {
                dispatch(permisionsError(e))
            })
    }

    function permisionsRequest() {
        return {
            type: PERMISIONS_REQUEST
        }
    }

    function permisionsSuccess(res) {
        return {
            type: PERMISIONS_SUCCESS,
            payload: res
        }
    }

    function permisionsError(e) {
        return {
            type: PERMISIONS_ERROR,
            payload: { error: e }
        }
    }
}

export function getUserGames(username, token, refreshToken) {
    const getBody = {
        params: {
            username,
            token,
            refreshToken
        }
    }

    return async function (dispatch) {
        dispatch(getUserGamesRequest())
        axios.get(process.env.REACT_APP_USER_GAMES, getBody)
            .then((res) => {
                dispatch(getUserGamesSuccess(res))
            }, (e) => {
                dispatch(getUserGamesError(e))
            })
    }

    function getUserGamesRequest() {
        return {
            type: USER_GAMES_REQUEST
        }
    }

    function getUserGamesSuccess(res) {
        return {
            type: USER_GAMES_SUCCESS,
            payload: res
        }
    }

    function getUserGamesError(e) {
        return {
            type: USER_GAMES_ERROR,
            payload: { error: e }
        }
    }
}