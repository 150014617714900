import {
  mapStateToProps,
  mapDispatchToProps
} from '../functions/mapStateToProps'

import { connect } from 'react-redux'

import ResetPasswordForm from "./ResetPasswordForm";

var ResetPasswordMainComponent = () => {
  var pathname = window.location.pathname.split('/')

  return (
    <div className='mt-5 text-center'>
      <div>
        <ResetPasswordForm username={pathname[pathname.length - 2]} token={pathname[pathname.length - 1]} />
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordMainComponent)
