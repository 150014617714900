import React, {
  Component
} from 'react'

import {
  mapStateToProps,
  mapDispatchToProps
} from '../functions/mapStateToProps'

import { connect } from 'react-redux'

import Join from './Join'
import Sign from './Sign'

var that

class Main extends Component {
  constructor(props) {
    super(props)
    that = this
  }

  signIn(e) {
    e.preventDefault()
    that.props.history.push("/signIn")
  }

  response() {
    if (that.props.userState.response === "User is not authorized") {
      let el = document.getElementById('show-logged-out')
      if (el) {
        el.classList.remove('d-none')
      }
      return <Sign />
    }

    if (that.props.userState.error) {
      let el = document.getElementById('show-logged-out')
      if (el) {
        el.classList.remove('d-none')
      }
      return <Sign />
    }

    if (that.props.userState.response === "User is authorized") {
      let el = document.getElementById('show-logged-out')
      if (el) {
        el.classList.add('d-none')
      }
      return <Join />
    }

    if (that.props.loging.username && that.props.loging.token && that.props.loging.refreshToken) {
      let el = document.getElementById('show-logged-out')
      if (el) {
        el.classList.add('d-none')
      }
      return <Join />
    }

    if (that.props.registering.username && that.props.registering.token && that.props.registering.refreshToken) {
      let el = document.getElementById('show-logged-out')
      if (el) {
        el.classList.add('d-none')
      }
      return <Join />
    }

    return <Sign />
  }

  render() {
    return (
      <div className='text-center'>
        <img src='/img/logo.svg' alt="Logo Main" className='logo-big my-5' />
        <p><iframe title="vimeo-player" src="https://player.vimeo.com/video/799878698?h=172eeda7c2&background=1" width="640" height="228" frameborder="0" allow="autoplay; fullscreen"></iframe></p>
        <h2 className='text-center mb-4 mx-3'>Welcome to your ideas assistant!</h2>
        <div className='text-center'>
          <p id="detection" className='mx-3'>{this.response()}</p>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
