import {
    GENERATE_IMAGE_ERROR,
    GENERATE_IMAGE_REQUEST,
    GENERATE_IMAGE_SUCCESS
} from '../actions/image-actions'

export default function generateImageReducer(state = {}, action) {
    switch (action.type) {
        case GENERATE_IMAGE_REQUEST:
            return "Generating image"
        case GENERATE_IMAGE_SUCCESS:
            if (action.payload.data.token) {
                window.localStorage.setItem("token", action.payload.data.token)
            }
            return (action.payload.data)
        case GENERATE_IMAGE_ERROR:
            var payload = action.payload.error.toString().split(' ')
            return { error: payload[payload.length - 1] }
        default:
            return state
    }
}