var Logout = () => {
  window.localStorage.removeItem('username')
  window.localStorage.removeItem('email')
  window.localStorage.removeItem('token')
  window.localStorage.removeItem('refreshToken')

  return (
    <div className='my-5'>
      <h2 className='text-center'>You have been logged out</h2>
    </div>
  )
}

export { Logout }
