var Footer = () => {
  return (
    <div className='position-fixed bottom w-100'>
      {/* <p className='text-center my-4'>
          <a href="mailto:support@thethinkinggrid.com">Email us</a>
        </p> */}
    </div>
  )
}

export { Footer }
