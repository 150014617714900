export var displayMessage = (mess) => {
    var message = document.getElementById('success')
    if (message) {
        message.classList.add('small-padding')
        message.textContent = mess
    }
}

export var hideMessage = () => {
    var message = document.getElementById('success')
    if (message) {
        message.classList.remove('small-padding')
        message.textContent = ''
    }
}

export var validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
}