import {
    INVITE_USER_ERROR,
    INVITE_USER_REQUEST,
    INVITE_USER_SUCCESS
} from '../actions/invite-actions'

export default function resetPasswordReducer(state = {}, action) {
    switch (action.type) {
        case INVITE_USER_REQUEST:
            return "Sending user invite"
        case INVITE_USER_SUCCESS:
            if (action.payload.data.token) {
                window.localStorage.setItem("token", action.payload.data.token)
            }
            return (action.payload.data)
        case INVITE_USER_ERROR:
            var payload = action.payload.error.toString().split(' ')
            return { error: payload[payload.length - 1] }
        default:
            return state
    }
}