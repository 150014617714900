import {
    HOST_BOARD_ERROR,
    HOST_BOARD_REQUEST,
    HOST_BOARD_SUCCESS,
    JOIN_BOARD_ERROR,
    JOIN_BOARD_REQUEST,
    JOIN_BOARD_SUCCESS,
    PERMISIONS_ERROR,
    PERMISIONS_REQUEST,
    PERMISIONS_SUCCESS,
    USER_GAMES_ERROR,
    USER_GAMES_REQUEST,
    USER_GAMES_SUCCESS
} from '../actions/game-actions'

export default function gameReducer(state = {}, action) {
    switch (action.type) {
        case HOST_BOARD_REQUEST:
            return "Requesting hosting game"
        case HOST_BOARD_SUCCESS:
            if (action.payload.data.token) {
                window.localStorage.setItem("token", action.payload.data.token)
            }
            return (action.payload.data)
        case HOST_BOARD_ERROR:
            return { status: "Not authorized" }
        case JOIN_BOARD_REQUEST:
            return "Requesting joining game"
        case JOIN_BOARD_SUCCESS:
            if (action.payload.data.token) {
                window.localStorage.setItem("token", action.payload.data.token)
            }
            return (action.payload.data)
        case JOIN_BOARD_ERROR:
            return { status: "Not authorized" }
        case PERMISIONS_REQUEST:
            return "Checking permision in game"
        case PERMISIONS_SUCCESS:
            if (action.payload.data.token) {
                window.localStorage.setItem("token", action.payload.data.token)
            }
            return (action.payload.data)
        case PERMISIONS_ERROR:
            return { status: "Not authorized" }
        case USER_GAMES_REQUEST:
            return "Checking user games permisions"
        case USER_GAMES_SUCCESS:
            if (action.payload.data.token) {
                window.localStorage.setItem("token", action.payload.data.token)
            }
            return (action.payload.data)
        case USER_GAMES_ERROR:
            return { status: "Not authorized" }
        default:
            return state
    }
}