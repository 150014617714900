import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import ReduxThunk from 'redux-thunk'
import reducers from './reducers'
import { App } from './components/App'
import './assets/scss/styles.scss';

const createStoreWithMiddleware = applyMiddleware(ReduxThunk)(createStore)

ReactDOM.render(
    <React.StrictMode>

    <Provider store={createStoreWithMiddleware(reducers)}>
        <App />
    </ Provider>
    </React.StrictMode>, document.getElementById('root'))