import React, { Component } from 'react'
import socketIOClient from 'socket.io-client'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Menu from './Menu'
import { Footer } from './Footer'
import Main from './Main'
import { Logout } from './Logout'
import MyProfile from './MyProfile'
import Sign from './Sign'
import Host from './Host'
import Game from './Game'
import ResetPasswordMainComponent from './ResetPasswordMainComponent'
import PageNotFound from './PageNotFound'
import JoinFromLink from './JoinFromLink'

const backendAddress = process.env.REACT_APP_BACKEND_ADDRES
var socket = socketIOClient(backendAddress, { 'transports': ['websocket'], 'force new connection': true });

class App extends Component {
  constructor(props) {
    super(props)
    console.log("Socket Connect")
    console.log(socket)
    this.state = {
      
      passEntered: window.localStorage.getItem('passEntered')
    }
  }

  render() {

    console.log(backendAddress)

    if (window.innerWidth < 1000) {
      alert('Thinking grid is not optimized to such small screens. For better use please use your laptop or computer.')
    }

    return (
      <Router>
        <Route component={Menu} />
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/signIn" component={Sign} />
          <Route exact path="/host" component={Host} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/joinFromLink" component={JoinFromLink} />
          <Route path="/game/:id" component={Game} />
          <Route path="/profile/:username" component={MyProfile} />
          <Route path="/resetPassword/:username/:token" component={ResetPasswordMainComponent} />
          <Route component={PageNotFound} />
        </Switch>
        <Route component={Footer} />
      </Router>
    )
  }
}

export { App, socket }
