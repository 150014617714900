import axios from 'axios'

export const RESET_PASSWORD_REQUEST = 'reset_password_request'
export const RESET_PASSWORD_SUCCESS = 'reset_password_success'
export const RESET_PASSWORD_ERROR = 'reset_password_error'
export const CHANGE_PASSWORD_REQUEST = 'change_password_request'
export const CHANGE_PASSWORD_SUCCESS = 'change_password_success'
export const CHANGE_PASSWORD_ERROR = 'change_password_error'

export function reset(data) {
    const postBody = {
        data
    }

    return async function (dispatch) {
        dispatch(checkIfValidRequest())
        axios.post(process.env.REACT_APP_RESET_PASSWORD_REQUEST, postBody)
            .then((res) => {
                dispatch(checkIfValidSuccess(res))
            }, (e) => {
                dispatch(checkIfValidError(e))
            })
    }

    function checkIfValidRequest() {
        return {
            type: RESET_PASSWORD_REQUEST
        }
    }

    function checkIfValidSuccess(res) {
        return {
            type: RESET_PASSWORD_SUCCESS,
            payload: res
        }
    }

    function checkIfValidError(e) {
        return {
            type: RESET_PASSWORD_ERROR,
            payload: { error: e }
        }
    }
}

export function setNewPassword(username, temporaryToken, newPassword) {
    const postBody = {
        username,
        temporaryToken,
        newPassword
    }

    return async function (dispatch) {
        dispatch(checkIfValidRequest())
        axios.post(process.env.REACT_APP_SET_NEW_PASSWORD, postBody)
            .then((res) => {
                dispatch(checkIfValidSuccess(res))
            }, (e) => {
                dispatch(checkIfValidError(e))
            })
    }

    function checkIfValidRequest() {
        return {
            type: RESET_PASSWORD_REQUEST
        }
    }

    function checkIfValidSuccess(res) {
        return {
            type: RESET_PASSWORD_SUCCESS,
            payload: res
        }
    }

    function checkIfValidError(e) {
        return {
            type: RESET_PASSWORD_ERROR,
            payload: { error: e }
        }
    }
}
