import React, {
  Component
} from 'react'
import { Redirect } from 'react-router-dom'

import { socket } from './App'

import {
  mapStateToProps,
  mapDispatchToProps
} from '../functions/mapStateToProps'

import { connect } from 'react-redux'

import { Chat } from './Chat'

import { validateEmail } from '../functions/utils'

var that, id, firstTime, adminOfRoom, roomId

let elements = [];
let checkAI = false;

class Game extends Component {
  constructor(props) {
    super(props)
    this.state = {
      titularRow: ['', '', '', '', '', '', '', '', '', '', '', ''],
      elements: [],
      rows: [
        ['', '', '', '', '', ''],
        ['', '', '', '', '', ''],
        ['', '', '', '', '', ''],
        ['', '', '', '', '', ''],
        ['', '', '', '', '', ''],
        ['', '', '', '', '', ''],
        ['', '', '', '', '', ''],
        ['', '', '', '', '', ''],
        ['', '', '', '', '', ''],
        ['', '', '', '', '', ''],
        ['', '', '', '', '', ''],
        ['', '', '', '', '', '']
      ],
      topic: '',
      playerRandoms: [],
      finished: false,
      finishedMain: false,
      typeIdea: '',

      inviteToGamePopup: false,
      genericPopup: false,
      genericPopupText: '',
      inviteState: 'Fill email input with user you want to invite and click invite button.'
    }

    // elements = [ ]
    checkAI = false;
    that = this
    firstTime = true
    adminOfRoom = false
    roomId = window.location.pathname.split('/')[2]
  }

  arrayEquals(a, b) {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
  }

  componentDidMount() {

    var path = window.location.pathname
    id = path.split('/')
    console.log("id ", id)

    if (id[2]) {
      socket.emit('joinRoom', id[2])
      that.props.checkPermisions(window.localStorage.getItem('username'), window.localStorage.getItem('token'), window.localStorage.getItem('refreshToken'), id[2])
    }
    socket.on('gameFinished', (props) => {
      this.setState({ finishedMain: true })
      var topic = document.getElementById('changing-topic')
      if (topic) {
        topic.classList.add('hidden')
        topic.classList.add('d-none')
      }

      var btns = document.getElementsByClassName('buttons')
      if (btns[0]) {
        btns[0].classList.add('hidden')
        btns[0].classList.add('d-none')
      }

      var msg = document.getElementsByClassName('msg-input')
      if (msg[0]) {
        msg[0].classList.add('hidden')
        msg[0].classList.add('d-none')
      }
    })

    //let elements = [];

    socket.off('connection').on('roll', (props) => {
      if (props.username === window.localStorage.getItem('username')) {

        //let elements = [];
        if (props.error) {

        } else {
          checkAI = true;
          for (var i = 0; i < props.scores.length; i++) {
            let el = document.getElementById(`field-${i}-${props.scores[i]}`)
            if (el) {
              let el2 = document.getElementById(window.localStorage.getItem('username'))
              if (el2) {
                if (!el2.style.backgroundColor) {
                  el.classList.add('choosen')
                } else {
                  el.style.backgroundColor = el2.style.backgroundColor
                }
              }
            }
            //test area
            let val = el.value;
            elements.push(val)
            that.setState({
              elements: [...that.state.elements, val]
            })
            if (elements.length > 8) elements.length = 8;
            //if (that.state.elements.length > 8) that.state.elements.length = 8;
          }
        }

      } else {
        var id = props.username
        let el2 = document.getElementById(id)
        if (el2) {
          var bg = el2.style.backgroundColor
          for (let j = 0; j < props.scores.length; j++) {
            var row = `row-${j + 1}`
            let el = document.getElementsByClassName(row)
            if (el.length > 0) {
              var elToAdd = document.createElement('div')
              elToAdd.className = 'dot'
              elToAdd.style.backgroundColor = bg
              el[0].children[props.scores[j]].children[1].appendChild(elToAdd)
            }
          }
        }
      }
    })

    socket.on('categoryChanged', (typeIdea) => {
      that.setState({
        typeIdea: typeIdea
      })
    })

    socket.on('fieldTitularUnlocked', (props) => {
      let items = [...that.state.titularRow]
      let item = props.value
      items[props.field] = item
      that.setState({
        titularRow: items
      })

      var el = document.getElementById('titular-' + props.field)
      if (el) {
        if (adminOfRoom) {
          el.disabled = false
        }
      }
    })

    socket.on('fieldTitularBlocked', (id) => {
      var el = document.getElementById('titular-' + id)
      if (el) {
        el.disabled = true
        let items = [...that.state.titularRow]
        let item = 'Someone is typing...'
        items[id] = item
        that.setState({
          titularRow: items
        })
      }
    })

    socket.on('fieldUnlocked', (props) => {
      let items = [...that.state.rows]
      let item = props.value
      var fld = props.field.split('-')
      items[fld[0]][fld[1]] = item
      that.setState({
        rows: items
      })

      var el = document.getElementById('field-' + props.field)
      if (el) {
        el.disabled = false
      }
    })

    socket.on('fieldBlocked', (idbc) => {
      var el = document.getElementById('field-' + idbc)
      if (el) {
        el.disabled = true
        var ids = idbc.split('-')
        let items = [...that.state.rows]
        let item = 'Someone is typing...'
        items[ids[0]][ids[1]] = item
        that.setState({
          rows: items
        })
      }
    })

    socket.on('generatedIdea', (props) => {
      let spaceText = props.replaceAll('\n', '<br/>')
      let newDiv = document.createElement("p")
      newDiv.classList.add('text-center');
      newDiv.innerHTML = spaceText
      let modalDiv = document.getElementById('chatGPTСlass')
      let responseDiv = document.getElementById('chatGPTId')
      responseDiv.innerHTML = " "
      responseDiv.appendChild(newDiv)
      checkAI = false;

      document.getElementById("ai-close-button").onclick = function () {
        modalDiv.classList.remove('ai-visible');
      }

      window.onclick = function (event) {
        if (event.target == modalDiv) {
          modalDiv.classList.remove('ai-visible');
        }
      }

      /*let buttonAI = document.getElementById('chat-gpt-button');
      function openModal(){
        modalDiv.classList.add('ai-visible');
      }
      buttonAI.onclick = openModal();*/

    })

    socket.on('wordsAssociated', (props) => {
      for (let i = 0; i < props.values.length; i++) {
        var el = document.getElementById(`field-${props.values[i].field}`)
        if (el) {
          let items = [...that.state.rows]
          let item = props.values[i].value
          var fld = props.values[i].field.split('-')
          items[fld[0]][fld[1]] = item
          that.setState({
            rows: items
          })
        }
      }
    })

    socket.on('topicChanged', (topic) => {
      that.setState({
        topic: topic
      })
    })
  }

  invitationResponse() {
    if (that.props.inviteUserState.error) {
      switch (that.props.inviteUserState.error) {
        case '401':
          if (that.state.inviteState !== "You are not authorized.") {
            that.setState({ inviteState: "You are not authorized." })
          }
          break;
        case '404':
          if (that.state.inviteState !== "Bad data sent in request.") {
            that.setState({ inviteState: "Bad data sent in request." })
          }
          break;
        case '405':
          if (that.state.inviteState !== "Grid not found.") {
            that.setState({ inviteState: "Grid not found." })
          }
          break;
        case '406':
          if (that.state.inviteState !== "Player you are trying to invite is already inside this grid.") {
            that.setState({ inviteState: "Player you are trying to invite is already inside this grid." })
          }
          break;
        case '418':
          if (that.state.inviteState !== "You cannot invite yourself to the grid.") {
            that.setState({ inviteState: "You cannot invite yourself to the grid." })
          }
          break;
        case '500':
          if (that.state.inviteState !== "Something went wrong! Please try again later.") {
            that.setState({ inviteState: "Something went wrong! Please try again later." })
          }
          break;
        default:
          if (that.state.inviteState !== "Something went wrong! Please try again later.") {
            that.setState({ inviteState: "Something went wrong! Please try again later." })
          }
          break;
      }
    }

    if (that.props.inviteUserState.status) {
      if (that.state.inviteState !== "User invited.") {
        that.setState({ inviteState: "User invited." })
      }
    }

  }

  componentDidUpdate(prevProps) {
    console.log("componentDidUpdate", this.props.game)

    if (prevProps.game !== this.props.game) {
      this.serverResponse()
    }
  }

  serverResponse() {
    if (that.props.game.status === "Not authorized") {
      return <Redirect to="/notAuthorized" />
    }

    if (that.props.game.game) {
      if (that.state.finished !== that.props.game.game.finished) {
        that.setState({ finished: that.props.game.game.finished })
      }

      if (firstTime) {
        firstTime = false
        

        /* Testing area */
        console.log("game scores ",  that.props.game.game.scores)
        console.log("game scores length ",  that.props.game.game.scores[0])
      if (that.props.game.game.scores[0]){
        for (let j = 0; j < that.props.game.game.scores.length; j++){
          if (that.props.game.game.scores[j].username == window.localStorage.getItem('username')){
            for (let i = 0; i < 8; i++){
            elements.push(that.props.game.game.fields[i][that.props.game.game.scores[j].scores[i]])
            /*let val = that.props.game.game.fields[i][that.props.game.game.scores[0].scores[i]]
            that.setState({
              elements: [...that.state.elements, val]
            })*/
          }
        }
          
              that.setState({
                elements: elements
              })
              checkAI = true
          }
      }else { }
      /* Testing area */

        that.setState({
          typeIdea: that.props.game.game.typeIdea,
          topic: that.props.game.game.topic,
          titularRow: that.props.game.game.topRow,
          rows: that.props.game.game.fields,
        })
      }
      if (that.props.game.game.players[0].toLowerCase() === window.localStorage.getItem('username').toLowerCase() && this.state.finishedMain !== true) {
        adminOfRoom = true
        let el = document.getElementById('changing-topic')
        if (el) {
          el.classList.remove('hidden')
          el.classList.remove('d-none')
        }
        el = document.getElementById('finish-game')
        if (el) {
          el.classList.remove('hidden')
          el.classList.remove('d-none')
        }
        el = document.getElementById('containerCategory')
        if (el) {
          el.classList.remove('hidden')
          el.classList.remove('d-none')
        }
        el = document.getElementById('get-associations')
        if (el) {
          el.classList.remove('hidden')
          el.classList.remove('d-none')
        }

        el = document.getElementById('invite-to-game')
        if (el) {
          el.classList.remove('hidden')
          el.classList.remove('d-none')
        }
      } else {
        let el = document.getElementById('titles')
        if (el) {
          for (var i = 0; i < el.childNodes.length; i++) {
            if (el.childNodes[i].childNodes[0]) {
              el.childNodes[i].childNodes[0].disabled = true
            }
          }
        }
      }

      var scores = undefined
      var otherScores = []
      for (let i = 0; i < that.props.game.game.scores.length; i++) {
        if (that.props.game.game.scores[i].username === window.localStorage.getItem('username')) {
          scores = that.props.game.game.scores[i].scores
        } else {
          otherScores.push(that.props.game.game.scores[i])
        }
      }

      

      if (otherScores.length > 0) {
        setTimeout(() => {
          var elsClearance = document.getElementsByClassName('dots-wrapper')
          for (let i = 0; i < elsClearance.length; i++) {
            elsClearance[i].innerHTML = ''
          }
          for (let i = 0; i < otherScores.length; i++) {
            var id = otherScores[i].username
            var el2 = document.getElementById(id)
            if (el2) {
              var bg = el2.style.backgroundColor
              for (let j = 0; j < otherScores[i].scores.length; j++) {
                var row = `row-${j + 1}`
                var el = document.getElementsByClassName(row)
                if (el.length > 0) {
                  var elToAdd = document.createElement('div')
                  elToAdd.className = 'dot'
                  elToAdd.style.backgroundColor = bg
                  if (el[0].children[otherScores[i].scores[j]].children[1]) {
                    el[0].children[otherScores[i].scores[j]].children[1].appendChild(elToAdd)
                  }
                }
              }
            }
          }
        }, 1000)
      }
      if (scores) {
        setTimeout(() => {
          var id = window.localStorage.getItem('username')
          var el2 = document.getElementById(id)
          if (el2) {
            var bg = el2.style.backgroundColor
            for (let i = 0; i < scores.length; i++) {
              var el = document.getElementById(`field-${i}-${scores[i]}`)
              if (el) {
                if (!bg) {
                  el.classList.add('choosen')
                } else {
                  el.style.backgroundColor = bg
                }
              }
            }
          }
        }, 1000)

      }

      if (that.props.game.game.finished) {
        var topic = document.getElementById('changing-topic')
        if (topic) {
          topic.classList.add('hidden')
          topic.classList.add('d-none')
        }

        var btns = document.getElementsByClassName('buttons')
        if (btns[0]) {
          btns[0].classList.add('hidden')
          btns[0].classList.add('d-none')
        }

        var msg = document.getElementsByClassName('msg-input')
        if (msg[0]) {
          msg[0].classList.add('hidden')
          msg[0].classList.add('d-none')
        }

        var ctg = document.getElementById('containerCategory')
        if (ctg) {
          ctg.classList.add('hidden')
          ctg.classList.add('d-none')
        }
      }
    }
  }

  setCategory(e) {
    //let ideaCategory = document.getElementById("type-idea").value;
    //let ideaCategory = e.target.value;
    /*that.setState({
      typeIdea: ideaCategory
    })*/
    var el = e.target;
    if (el) {
      if (id[2]) {
        var category = e.target;
        if (category && !that.state.finished) {
          socket.emit('changeCategory', {
            typeIdea: category.value,
            id: id[2],
            username: window.localStorage.getItem('username'),
            token: window.localStorage.getItem('token'),
            refreshToken: window.localStorage.getItem('refreshToken')
          })
          //topic.value = ''
        }
      }
    }
  }

  /*changeKeywords(){
    if (that.state.elements) {
      if (id[2]) {
        if (that.state.elements && !that.state.finished) {
          socket.emit('changeKeywords', {
            elements: that.state.elements,
            id: id[2],
            username: window.localStorage.getItem('username'),
            token: window.localStorage.getItem('token'),
            refreshToken: window.localStorage.getItem('refreshToken')
          })
        }
      }
    }
  }*/

  change() {
    var el = document.getElementById('topic')
    document.getElementById('titular-0').focus();
    if (el) {
      if (id[2]) {
        var topic = document.getElementById('topic')
        if (topic && !that.state.finished) {
          socket.emit('changeTopic', {
            topic: topic.value,
            id: id[2],
            username: window.localStorage.getItem('username'),
            token: window.localStorage.getItem('token'),
            refreshToken: window.localStorage.getItem('refreshToken')
          })
          topic.value = ''
        }
      }
    }
  }

  blockTitularField(e) {
    var idOfField = e.target.id.split('-')
    if (idOfField[1]) {
      socket.emit('blockTitular', {
        field: idOfField[1],
        id: id[2],
        username: window.localStorage.getItem('username'),
        token: window.localStorage.getItem('token'),
        refreshToken: window.localStorage.getItem('refreshToken')
      })
    }
  }

  blockField(e) {
    var idOfField = e.target.id.split('-')
    if (idOfField[1] && idOfField[2]) {
      socket.emit('blockRegular', {
        field: idOfField[1] + '-' + idOfField[2],
        id: id[2],
        username: window.localStorage.getItem('username'),
        token: window.localStorage.getItem('token'),
        refreshToken: window.localStorage.getItem('refreshToken')
      })
    }
  }

  unlockTitularField(e) {
    var idOfField = e.target.id.split('-')
    if (idOfField[1]) {
      socket.emit('unlockTitular', {
        value: e.target.value,
        field: idOfField[1],
        id: id[2],
        username: window.localStorage.getItem('username'),
        token: window.localStorage.getItem('token'),
        refreshToken: window.localStorage.getItem('refreshToken')
      })
    }
  }

  unlockField(e) {
    var idOfField = e.target.id.split('-')
    if (idOfField[1] && idOfField[2]) {
      socket.emit('unlockRegular', {
        value: e.target.value,
        field: idOfField[1] + '-' + idOfField[2],
        id: id[2],
        username: window.localStorage.getItem('username'),
        token: window.localStorage.getItem('token'),
        refreshToken: window.localStorage.getItem('refreshToken')
      })
    }
  }

  changeTitularFieldValue(e) {
    if (!that.state.finished) {
      var id = e.target.id.split('-')
      let items = [...that.state.titularRow]
      let item = items[id[1]]
      item = e.target.value
      items[id[1]] = item

      that.setState({
        titularRow: items
      })
    }
  }

  changeFieldValue(e) {
    if (!that.state.finished) {
      var id = e.target.id.split('-')
      let items = [...that.state.rows]
      let item = items[id[1]][id[2]]
      item = e.target.value
      items[id[1]][id[2]] = item

      that.setState({
        rows: items
      })
    }
  }



  roll() {
    if (id[2] && !that.state.finished) {

      socket.emit('rolling', {
        id: id[2],
        username: window.localStorage.getItem('username'),
        token: window.localStorage.getItem('token'),
        refreshToken: window.localStorage.getItem('refreshToken')
      })
    }
  }

  generateIdea() {
    if (that.state.topic != '') {
      if (that.state.typeIdea != ''){
        if(checkAI == true){
          let standBy = document.getElementById('waitingGPT')
          if(standBy){
            standBy.innerHTML = "We're thinking of ideas, please stand by!"
          let appearDiv = document.getElementById('chatGPTСlass')
          appearDiv.classList.add('ai-visible')
          if (that.state.elements.length > 12) that.state.elements.length = 12
          let props = {
            elements: that.state.elements,
            typeIdea: that.state.typeIdea,
            topic: that.state.topic
          }
          //socket.emit('generateIdea', {elements: that.state.elements}, {typeIdea})
          socket.emit('generateIdea', (props))
          }

         /* 
          let appearDiv = document.getElementById('chatGPTСlass')
          appearDiv.classList.add('ai-visible')
          if (that.state.elements.length > 12) that.state.elements.length = 12
          let props = {
            elements: that.state.elements,
            typeIdea: that.state.typeIdea,
            topic: that.state.topic
          }
          //socket.emit('generateIdea', {elements: that.state.elements}, {typeIdea})
          socket.emit('generateIdea', (props)) 
          */
        }

        else {
          let modalDiv = document.getElementById('chatGPTСlass')
          modalDiv.classList.add('ai-visible')
    
          let standBy = document.getElementById('waitingGPT')
          if(standBy){
            standBy.innerHTML = "You have to roll the dice to get your prompts in order to generate an idea with AI"
          }
    
          document.getElementById("ai-close-button").onclick = function () {
            modalDiv.classList.remove('ai-visible');
          }
    
          window.onclick = function (event) {
            if (event.target == modalDiv) {
              modalDiv.classList.remove('ai-visible');
            }
          }
        }
      }

      else {
        let modalDiv = document.getElementById('chatGPTСlass')
        modalDiv.classList.add('ai-visible')
  
        let standBy = document.getElementById('waitingGPT')
        if(standBy){
          standBy.innerHTML = "The category hasn't been set yet. The host of the grid has to select a category from the dropdown in order to continue."
        }
  
        document.getElementById("ai-close-button").onclick = function () {
          modalDiv.classList.remove('ai-visible');
        }
  
        window.onclick = function (event) {
          if (event.target == modalDiv) {
            modalDiv.classList.remove('ai-visible');
          }
        }
      }
    }

    else {
      let modalDiv = document.getElementById('chatGPTСlass')
      modalDiv.classList.add('ai-visible')

      let standBy = document.getElementById('waitingGPT')
      if(standBy){
        standBy.innerHTML = "The topic must be set in order to continue. To do so, your grid host has to set it by writing on the box and pressing the button 'Set'"
      }
      
      document.getElementById("ai-close-button").onclick = function () {
        modalDiv.classList.remove('ai-visible');
      }

      window.onclick = function (event) {
        if (event.target == modalDiv) {
          modalDiv.classList.remove('ai-visible');
        }
      }
    }
  }

 

  finishGame() {
    if (id[2]) {
      // this.setState({ finished: true })
      socket.emit('finishGame', {
        id: id[2],
        username: window.localStorage.getItem('username'),
        token: window.localStorage.getItem('token'),
        refreshToken: window.localStorage.getItem('refreshToken')
      })
    }
  }

  async getAssociations() {
    // elements = [ ]
    var values = []
    for (let i = 0; i < 8; i++) {
      let el = document.getElementById(`titular-${i}`)
      if (el) {
        var val = el.value.replaceAll(' ', '+')
        var res = await fetch(`https://api.datamuse.com/words?ml=${val}`)
        var data = await res.json()
        for (let j = 0; j < 6; j++) {
          if (data[j]) {
            var el2 = document.getElementById(`field-${i}-${j}`)
            if (el2) {
              if (el2.value.trim() === '') {
                if (id[2]) {
                  el2.value = data[j].word
                  values.push({
                    value: el2.value,
                    field: `${i}-${j}`
                  })
                }
              }
            }

          } else {
            break
          }
        }
      }
    }

    if (id[2]) {
      socket.emit('adminAssociate', {
        values: values,
        id: id[2],
        username: window.localStorage.getItem('username'),
        token: window.localStorage.getItem('token'),
        refreshToken: window.localStorage.getItem('refreshToken')
      })
    }
  }

  componentWillUnmount() {
    if (id[2]) {
      socket.emit('leaveRoom', id[2])
    }
  }

  focused() {
    console.log('focused')
  }

  focusedOut() {
    console.log('focused out')
  }

  inviteToGame() {
    if (!that.state.inviteToGamePopup) {
      that.setState({
        inviteToGamePopup: true,
        inviteState: 'Fill email input with user you want to invite and click invite button.'
      })
    } else {
      that.setState({
        inviteToGamePopup: false,
        inviteState: 'Fill email input with user you want to invite and click invite button.'
      })
    }
  }

  renderInviteToGameBackground() {
    if (that.state.inviteToGamePopup) {
      return (
        <div className='position-fixed w-100 h-100 z-index-on-top background-shadowy background-to-top' onClick={() => {
          that.setState({
            inviteToGamePopup: false
          })
        }}>

        </div>
      )
    } else {
      return <div></div>
    }
  }

  sendInvitationRequest() {
    var emailField = document.getElementById('email-inv')
    if (emailField) {
      var value = emailField.value
      emailField.value = ''
      if (value.trim() !== '') {
        if (validateEmail(value.trim())) {
          that.setState({
            inviteState: 'Sending email.'
          })
          that.props.inviteUser(value, roomId, window.localStorage.getItem('username'), window.localStorage.getItem('token'), window.localStorage.getItem('refreshToken'))
        } else {
          that.setState({
            inviteState: 'This is not an email.'
          })
        }
      } else {
        that.setState({
          inviteState: 'Email input cannot be empty.'
        })
      }
    }
  }

  renderInviteToGamePopup() {
    if (that.state.inviteToGamePopup) {
      return (
        <div className='position-fixed w-100 h-100 z-index-on-top' onClick={() => {
          that.setState({
            inviteToGamePopup: false
          })
        }}>
          <div className='pop-up' onClick={() => {
            that.setState({
              inviteToGamePopup: false
            })
          }}>
            <div className='px-4'>
              <p className='my-2'>{that.state.inviteState}</p>
              <div className='text-right'>
                <button
                  className='btn own-btn grey-btn my-4'
                  id="quit">
                  Quit
                </button>
              </div>
              <input id="email-inv" placeholder="Email" className='own-input' type='text' onClick={(e) => {
                e.stopPropagation()
              }} onKeyPress={(e) => {

                if (e.code == "Enter") that.sendInvitationRequest()
              }
              } />
              <button
                className='btn own-btn grey-btn mx-4 my-4'
                id="invite-to-game-by-email"
                onClick={(e) => {
                  e.stopPropagation()
                  that.sendInvitationRequest()
                }}>
                Invite
              </button>
            </div>
          </div>
        </div>
      )
    } else {
      return <div></div>
    }
  }


  renderGenericPopup() {
    if (that.state.genericPopup) {
      return (
        <div className='position-fixed w-100 h-100 z-index-on-top'>
          <div className='pop-up d-flex justify-content-center align-items-center px-4'>
            <div className=''>
              <p className='my-auto'>{that.state.genericPopupText}</p>
            </div>
          </div>
        </div>
      )
    } else {
      return <div></div>
    }
  }

  setGenericPopup(text) {
    that.setState({
      genericPopupText: text,
      genericPopup: true
    })

    setTimeout(() => {
      that.setState({
        genericPopup: false
      })
    }, 3000);


  }

  checkGenerationResponse() {
    console.log(that.props.generatingImage)
  }

  generateImage() {
    that.props.generateImage(window.localStorage.getItem('username'), window.localStorage.getItem('token'), window.localStorage.getItem('refreshToken'), id[2])
  }

  render() {
    return (
      <div className='game'>
        {this.serverResponse()}
        {this.invitationResponse()}
        {this.renderInviteToGameBackground()}
        {this.renderInviteToGamePopup()}
        {this.renderGenericPopup()}

        {this.checkGenerationResponse()}
        <div className='text-center'>
          <a href="/"><img src='/img/logo.svg' className='logo-big' alt='logo'/></a>
        </div>
        <div className='mx-auto max-content my-5'>
          <div className='d-sm-flex text-center mx-3'>
            <p>Board ID: <b>{roomId}</b></p>
          </div>
        </div>
        <div className='max-content my-5'>
          {/* <div className='mx-4'>
            <p><b>ADMIN:</b></p>
            <p>1. Formulate problem you want to solve and put it in the topic field. </p>
            <p>2. Split up topic into keywords section.</p>
            <p>3. (Optional) Click on Get Associations button to get words connected with keywords.</p>
            <p><b>ALL USERS:</b></p>
            <p>4. Fill up board with words related to certain keyword - so f.e Keyword: Colors - Words: red, blue, green, etc.</p>
            <p>5. When all fields are filled up use dice button to generate your sequence. It will light up your words. </p>
            <p>6. Try to formulate the idea from your words which was light up. You don't have to use them all! To not forget this ideas, send them in Idea Sharing chat. </p>
            <p>7. (Optional) If you want you can send image of the board with ideas to your email, by clicking Download Board button.</p>
          </div> */}
        </div>
        {/*<div className='buttons mb-3 mx-auto max-content'>
          
          <button
            className='btn own-btn grey-btn hidden d-none mx-4'
            id="invite-to-game"
            onClick={this.inviteToGame}>
            Invite to Grid
          </button>
        </div>
        */}

        <div id="containerCategory" className='mx-auto max-content my-5 hidden d-none'>
          <div className='d-sm-flex text-center mx-3 flex-column'>
            <label for="type-idea">What ideas are you trying to come up with?:</label>
            <select className='btn own-btn grey-btn text-start-dd' id="type-idea" name="type-idea" onChange={this.setCategory}>
              <option className='btn own-btn grey-btn text-start-dd' selected disabled value="anything">Choose a category</option>
              <option className='btn own-btn grey-btn text-start-dd' value="brand name">Brand name</option>
              <option className='btn own-btn grey-btn text-start-dd' value="product">Product</option>
              <option className='btn own-btn grey-btn text-start-dd' value="marketing campaign">Marketing Campaign</option>
              <option className='btn own-btn grey-btn text-start-dd' value="event campaign">Event Campaign</option>
              <option className='btn own-btn grey-btn text-start-dd' value="social media campaign">Social Media Campaign</option>
              <option className='btn own-btn grey-btn text-start-dd' value="campaign performance">Campaign Performance</option>
              <option className='btn own-btn grey-btn text-start-dd' value="project">Project</option>
            </select>
          </div>
        </div>

        <div className='mx-auto max-content my-5'>
          <div className='d-sm-flex text-center mx-3 flex-column'>
            <p>What is this <b>{that.state.typeIdea}</b> for? Describe it shortly: <b>{that.state.topic}</b></p>
            <div id="changing-topic" className='hidden d-none d-flex'>
              <input id="topic" className='own-input ms-2 top--margin-fix' type='text' placeholder='Shortly provide details (max 50 characters)' maxlength="50" />
              <button
                className='ms-2 own-btn btn grey-btn top--margin-fix'
                onClick={this.change}>
                {that.state.topic == "" ? "Set" : "Change"}
              </button>
            </div>
          </div>
        </div>

        <div className='max-content mx-auto'>
          <div className='mb-3'>
            <div className='text-center d-flex'>
              <div className='keywords mx-auto'><b>Keyword 1</b></div>
              <div className='keywords mx-auto'><b>Keyword 2</b></div>
              <div className='keywords mx-auto'><b>Keyword 3</b></div>
              <div className='keywords mx-auto'><b>Keyword 4</b></div>
              <div className='keywords mx-auto'><b>Keyword 5</b></div>
              <div className='keywords mx-auto'><b>Keyword 6</b></div>
              <div className='keywords mx-auto'><b>Keyword 7</b></div>
              <div className='keywords mx-auto'><b>Keyword 8</b></div>
              <div className='w-spacer'></div>
              <div className='keywords mx-auto'><b>Example Keyword</b></div>
            </div>
          </div>
          <div id="titles" className='d-flex titular-row mb-3'>
            <div className='title'>
              <textarea
                className="textarea"
                id="titular-0"
                onFocus={this.blockTitularField}
                value={that.state.titularRow[0]}
                onChange={this.changeTitularFieldValue}
                onBlur={this.unlockTitularField}
              >
              </textarea>
            </div>
            <div className='title'>
              <textarea
                className="textarea"
                id="titular-1"
                onFocus={this.blockTitularField}
                value={that.state.titularRow[1]}
                onChange={this.changeTitularFieldValue}
                onBlur={this.unlockTitularField}
              >
              </textarea>
            </div>
            <div className='title'>
              <textarea
                className="textarea"
                id="titular-2"
                onFocus={this.blockTitularField}
                value={that.state.titularRow[2]}
                onChange={this.changeTitularFieldValue}
                onBlur={this.unlockTitularField}
              >
              </textarea>
            </div>
            <div className='title'>
              <textarea
                className="textarea"
                id="titular-3"
                onFocus={this.blockTitularField}
                value={that.state.titularRow[3]}
                onChange={this.changeTitularFieldValue}
                onBlur={this.unlockTitularField}
              >
              </textarea>
            </div>
            <div className='title'>
              <textarea
                className="textarea"
                id="titular-4"
                onFocus={this.blockTitularField}
                value={that.state.titularRow[4]}
                onChange={this.changeTitularFieldValue}
                onBlur={this.unlockTitularField}
              >
              </textarea>
            </div>
            <div className='title'>
              <textarea
                className="textarea"
                id="titular-5"
                onFocus={this.blockTitularField}
                value={that.state.titularRow[5]}
                onChange={this.changeTitularFieldValue}
                onBlur={this.unlockTitularField}
              >
              </textarea>
            </div>
            <div className='title'>
              <textarea
                className="textarea"
                id="titular-6"
                onFocus={this.blockTitularField}
                value={that.state.titularRow[6]}
                onChange={this.changeTitularFieldValue}
                onBlur={this.unlockTitularField}
              >
              </textarea>
            </div>

            <div className='title'>
              <textarea
                className="textarea"
                id="titular-7"
                onFocus={this.blockTitularField}
                value={that.state.titularRow[7]}
                onChange={this.changeTitularFieldValue}
                onBlur={this.unlockTitularField}
              >
              </textarea>
            </div>
            <div className='w-spacer'></div>
            <div className='title'>
              <div
                className="textarea"
              >
                Colors
              </div>
            </div>

            <div className='title hidden d-none'>
              <textarea
                className="textarea col-1"
                id="titular-8"
                onFocus={this.blockTitularField}
                value={that.state.titularRow[8]}
                onChange={this.changeTitularFieldValue}
                onBlur={this.unlockTitularField}
              >
              </textarea>
            </div>
            <div className='title hidden d-none'>
              <textarea
                className="textarea"
                id="titular-9"
                onFocus={this.blockTitularField}
                value={that.state.titularRow[9]}
                onChange={this.changeTitularFieldValue}
                onBlur={this.unlockTitularField}
              >
              </textarea>
            </div>
            <div className='title hidden d-none'>
              <textarea
                className="textarea"
                id="titular-10"
                onFocus={this.blockTitularField}
                value={that.state.titularRow[10]}
                onChange={this.changeTitularFieldValue}
                onBlur={this.unlockTitularField}
              >
              </textarea>
            </div>
            <div className='title hidden d-none'>
              <textarea
                className="textarea"
                id="titular-11"
                onFocus={this.blockTitularField}
                value={that.state.titularRow[11]}
                onChange={this.changeTitularFieldValue}
                onBlur={this.unlockTitularField}
              >
              </textarea>
            </div>
          </div>
        </div>
        <div className='max-content mx-auto mb-5'>
          <div className='d-flex'>
            <div className='answer-row row-1'>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-0-0"
                  onFocus={this.blockField}
                  value={that.state.rows[0][0]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-0-1"
                  onFocus={this.blockField}
                  value={that.state.rows[0][1]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-0-2"
                  onFocus={this.blockField}
                  value={that.state.rows[0][2]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-0-3"
                  onFocus={this.blockField}
                  value={that.state.rows[0][3]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-0-4"
                  onFocus={this.blockField}
                  value={that.state.rows[0][4]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-0-5"
                  onFocus={this.blockField}
                  value={that.state.rows[0][5]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
            </div>

            <div className='answer-row row-2'>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-1-0"
                  onFocus={this.blockField}
                  value={that.state.rows[1][0]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-1-1"
                  onFocus={this.blockField}
                  value={that.state.rows[1][1]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-1-2"
                  onFocus={this.blockField}
                  value={that.state.rows[1][2]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-1-3"
                  onFocus={this.blockField}
                  value={that.state.rows[1][3]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-1-4"
                  onFocus={this.blockField}
                  value={that.state.rows[1][4]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-1-5"
                  onFocus={this.blockField}
                  value={that.state.rows[1][5]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
            </div>

            <div className='answer-row row-3'>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-2-0"
                  onFocus={this.blockField}
                  value={that.state.rows[2][0]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-2-1"
                  onFocus={this.blockField}
                  value={that.state.rows[2][1]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-2-2"
                  onFocus={this.blockField}
                  value={that.state.rows[2][2]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-2-3"
                  onFocus={this.blockField}
                  value={that.state.rows[2][3]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-2-4"
                  onFocus={this.blockField}
                  value={that.state.rows[2][4]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-2-5"
                  onFocus={this.blockField}
                  value={that.state.rows[2][5]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
            </div>

            <div className='answer-row row-4'>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-3-0"
                  onFocus={this.blockField}
                  value={that.state.rows[3][0]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-3-1"
                  onFocus={this.blockField}
                  value={that.state.rows[3][1]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-3-2"
                  onFocus={this.blockField}
                  value={that.state.rows[3][2]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-3-3"
                  onFocus={this.blockField}
                  value={that.state.rows[3][3]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-3-4"
                  onFocus={this.blockField}
                  value={that.state.rows[3][4]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-3-5"
                  onFocus={this.blockField}
                  value={that.state.rows[3][5]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
            </div>

            <div className='answer-row row-5'>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-4-0"
                  onFocus={this.blockField}
                  value={that.state.rows[4][0]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-4-1"
                  onFocus={this.blockField}
                  value={that.state.rows[4][1]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-4-2"
                  onFocus={this.blockField}
                  value={that.state.rows[4][2]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-4-3"
                  onFocus={this.blockField}
                  value={that.state.rows[4][3]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-4-4"
                  onFocus={this.blockField}
                  value={that.state.rows[4][4]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-4-5"
                  onFocus={this.blockField}
                  value={that.state.rows[4][5]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
            </div>

            <div className='answer-row row-6'>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-5-0"
                  onFocus={this.blockField}
                  value={that.state.rows[5][0]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-5-1"
                  onFocus={this.blockField}
                  value={that.state.rows[5][1]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-5-2"
                  onFocus={this.blockField}
                  value={that.state.rows[5][2]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-5-3"
                  onFocus={this.blockField}
                  value={that.state.rows[5][3]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-5-4"
                  onFocus={this.blockField}
                  value={that.state.rows[5][4]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-5-5"
                  onFocus={this.blockField}
                  value={that.state.rows[5][5]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
            </div>

            <div className='answer-row row-7'>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-6-0"
                  onFocus={this.blockField}
                  value={that.state.rows[6][0]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-6-1"
                  onFocus={this.blockField}
                  value={that.state.rows[6][1]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-6-2"
                  onFocus={this.blockField}
                  value={that.state.rows[6][2]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-6-3"
                  onFocus={this.blockField}
                  value={that.state.rows[6][3]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-6-4"
                  onFocus={this.blockField}
                  value={that.state.rows[6][4]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-6-5"
                  onFocus={this.blockField}
                  value={that.state.rows[6][5]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
            </div>

            <div className='answer-row row-8'>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-7-0"
                  onFocus={this.blockField}
                  value={that.state.rows[7][0]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-7-1"
                  onFocus={this.blockField}
                  value={that.state.rows[7][1]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-7-2"
                  onFocus={this.blockField}
                  value={that.state.rows[7][2]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-7-3"
                  onFocus={this.blockField}
                  value={that.state.rows[7][3]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-7-4"
                  onFocus={this.blockField}
                  value={that.state.rows[7][4]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-7-5"
                  onFocus={this.blockField}
                  value={that.state.rows[7][5]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
            </div>

            <div className='w-spacer'></div>
            <div className='answer-row'>
              <div className='fields'>
                <div
                  className="textarea"
                >
                  Red
                </div>
              </div>
              <div className='fields'>
                <div
                  className="textarea"
                >
                  Blue
                </div>
              </div>
              <div className='fields'>
                <div
                  className="textarea"
                >
                  Green
                </div>
              </div>
              <div className='fields'>
                <div
                  className="textarea"
                >
                  Yellow
                </div>
              </div>
              <div className='fields'>
                <div
                  className="textarea"
                >
                  Purple
                </div>
              </div>
              <div className='fields'>
                <div
                  className="textarea"
                >
                  Pink
                </div>
              </div>
            </div>


            <div className='answer-row row-9 hidden d-none'>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-8-0"
                  onFocus={this.blockField}
                  value={that.state.rows[8][0]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-8-1"
                  onFocus={this.blockField}
                  value={that.state.rows[8][1]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-8-2"
                  onFocus={this.blockField}
                  value={that.state.rows[8][2]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-8-3"
                  onFocus={this.blockField}
                  value={that.state.rows[8][3]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-8-4"
                  onFocus={this.blockField}
                  value={that.state.rows[8][4]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-8-5"
                  onFocus={this.blockField}
                  value={that.state.rows[8][5]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
            </div>

            <div className='answer-row row-10 hidden d-none'>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-9-0"
                  onFocus={this.blockField}
                  value={that.state.rows[9][0]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-9-1"
                  onFocus={this.blockField}
                  value={that.state.rows[9][1]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-9-2"
                  onFocus={this.blockField}
                  value={that.state.rows[9][2]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-9-3"
                  onFocus={this.blockField}
                  value={that.state.rows[9][3]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-9-4"
                  onFocus={this.blockField}
                  value={that.state.rows[9][4]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-9-5"
                  onFocus={this.blockField}
                  value={that.state.rows[9][5]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
            </div>

            <div className='answer-row row-11 hidden d-none'>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-10-0"
                  onFocus={this.blockField}
                  value={that.state.rows[10][0]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-10-1"
                  onFocus={this.blockField}
                  value={that.state.rows[10][1]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-10-2"
                  onFocus={this.blockField}
                  value={that.state.rows[10][2]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-10-3"
                  onFocus={this.blockField}
                  value={that.state.rows[10][3]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-10-4"
                  onFocus={this.blockField}
                  value={that.state.rows[10][4]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-10-5"
                  onFocus={this.blockField}
                  value={that.state.rows[10][5]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea></div>
              <div className='dots-wrapper'></div>
            </div>

            <div className='answer-row row-12 hidden d-none'>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-11-0"
                  onFocus={this.blockField}
                  value={that.state.rows[11][0]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-11-1"
                  onFocus={this.blockField}
                  value={that.state.rows[11][1]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-11-2"
                  onFocus={this.blockField}
                  value={that.state.rows[11][2]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-11-3"
                  onFocus={this.blockField}
                  value={that.state.rows[11][3]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-11-4"
                  onFocus={this.blockField}
                  value={that.state.rows[11][4]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
              <div className='fields'>
                <textarea
                  className="textarea"
                  id="field-11-5"
                  onFocus={this.blockField}
                  value={that.state.rows[11][5]}
                  onChange={this.changeFieldValue}
                  onBlur={this.unlockField}>
                </textarea>
                <div className='dots-wrapper'></div>
              </div>
            </div>
          </div>
        </div>
        <img className='d-none' src='/img/reset-button-active.png' alt='roll' />
        <div className="buttons mb-5 mx-auto max-content">
          <button
            className='btn own-btn'
            onClick={this.roll}>
            <img className='roll' src='/img/reset-button-inactive.png' alt='roll' />
          </button>

          <button
            className='btn own-btn hidden d-none grey-btn mx-4'
            id="get-associations"
            onClick={this.getAssociations}>
            Get Associations
          </button>

          <button
            className='btn own-btn grey-btn mx-4'
            id="chat-gpt-button"
            onClick={
              this.generateIdea
            }
          >
            Generate AI ideas
          </button>
        </div>
        <div className="buttons mb-5 mx-auto max-content">
          <button
            className='btn own-btn grey-btn mx-4'
            id="generate-image"
            onClick={() => {
              this.generateImage()
              this.setGenericPopup("Check your mailbox for the copy of the grid")
            }}
          >
            Save Board
          </button>

          
          <button
            className='btn own-btn grey-btn hidden d-none mx-4'
            id="finish-game"
            onClick={() => {
              this.finishGame()
              this.setGenericPopup("Grid has been locked")
            }}
          >
            Finish Grid
          </button>
        </div>
        <div id="chatGPTСlass" class="text-center ai-modal ai-hidden">
          <div id="ai-modal-content-id" class="ai-modal-content">
            <span id="ai-close-button" class="ai-close">&times;</span>
            <div id="chatGPTId" class="generated-content">
              <p id="waitingGPT" class="text-center ai-generated">We're thinking of ideas, please stand by!</p>
            </div>
          </div>
        </div>
        <Chat />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Game)
