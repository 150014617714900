import axios from 'axios'

export const GENERATE_IMAGE_REQUEST = 'invite_user_request'
export const GENERATE_IMAGE_SUCCESS = 'invite_user_success'
export const GENERATE_IMAGE_ERROR = 'invite_user_error'

export function generateImage(username, token, refreshToken, board) {
    return async function (dispatch) {
        dispatch(checkIfValidRequest())
        axios.get(process.env.REACT_APP_GENERATE_IMAGE_ADDRESS + `?username=${username}&token=${token}&refreshToken=${refreshToken}&board=${board}`)
            .then((res) => {
                dispatch(checkIfValidSuccess(res))
            }, (e) => {
                dispatch(checkIfValidError(e))
            })
    }

    function checkIfValidRequest() {
        return {
            type: GENERATE_IMAGE_REQUEST
        }
    }

    function checkIfValidSuccess(res) {
        return {
            type: GENERATE_IMAGE_SUCCESS,
            payload: res
        }
    }

    function checkIfValidError(e) {
        return {
            type: GENERATE_IMAGE_ERROR,
            payload: { error: e }
        }
    }
}