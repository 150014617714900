import axios from 'axios'

export const REGISTER_REQUEST = 'register_request'
export const REGISTER_SUCCESS = 'register_success'
export const REGISTER_ERROR = 'register_error'
export const REGISTER_DELETE = 'register_delete'

export function register(username, password, email) {
    const postBody = {
        username,
        password,
        email
    }

    return async function (dispatch) {
        dispatch(registerRequest())
        axios.post(process.env.REACT_APP_REGISTER_ADDRESS, postBody)
            .then((res) => {
                dispatch(registerSuccess(res))
            }, (e) => {
                dispatch(registerError(e))
            })
    }

    function registerRequest() {
        return {
            type: REGISTER_REQUEST
        }
    }

    function registerSuccess(res) {
        return {
            type: REGISTER_SUCCESS,
            payload: res
        }
    }

    function registerError(e) {
        return {
            type: REGISTER_ERROR,
            payload: { error: e }
        }
    }
}

export function registerDelete() {
    return async function (dispatch) {
        dispatch(registerDel())
    }

    function registerDel() {
        return {
            type: REGISTER_DELETE
        }
    }
}