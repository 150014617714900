import { combineReducers } from 'redux'
import UserReducer from './user-reducer'
import LoginReducer from './login-reducer'
import RegisterReducer from './register-reducer'
import GameReducer from './game-reducer'
import ResetPasswordReducer from './reset-password-reducer'
import InviteReducer from './invite-reducer'
import ImageReducer from './image-reducer'

const rootReducer = combineReducers({
    user: UserReducer,
    login: LoginReducer,
    register: RegisterReducer,
    game: GameReducer,
    passwordReset: ResetPasswordReducer,
    inviteUser: InviteReducer,
    generateImage: ImageReducer
})

export default rootReducer