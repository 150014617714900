import React, {
  Component
} from 'react'

import {
  mapStateToProps,
  mapDispatchToProps
} from '../functions/mapStateToProps'

import { connect } from 'react-redux'

var that

class Sign extends Component {
  constructor(props) {
    super(props)
    this.state = { message: '' }
    that = this
  }

  componentDidMount() {
    this.removeAll()
  }

  removeAll() {
    var error = document.getElementById('error')
    if (error) {
      error.classList.remove('small-padding')
      error.textContent = ""
    }

    that.setState({ message: '' })
  }

  notEmail() {
    that.setState({ message: 'This is not an email' })
  }

  passwordsNotIdentical() {
    that.setState({ message: 'Passwords are not identical' })
  }

  checkBoxNotChecked() {
    that.setState({ message: 'You need to agree with Privacy Policy and Terms and Conditions' })
  }

  fillForm() {
    that.setState({ message: 'You need to fill all form fields' })
  }

  checkIfCheckboxChecked() {
    var checkbox = document.getElementById("checkbox")
    if (checkbox) {
      return checkbox.checked
    }
    return false
  }

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  signUp() {
    that.removeAll()
    var emailInput = document.getElementById("remail")
    var loginInput = document.getElementById("rusername")
    var passwordInput = document.getElementById("rpassword")
    var repeatPasswordInput = document.getElementById("rpassword-repeat")
    window.localStorage.setItem("tried", "register")
    if (loginInput && passwordInput && repeatPasswordInput && emailInput) {
      if (that.checkIfCheckboxChecked()) {
        if (that.validateEmail(emailInput.value)) {
          if (passwordInput.value === repeatPasswordInput.value) {
            if (passwordInput.value === '' || loginInput.value === '') {
              that.fillForm()
              return
            }
            that.props.register(loginInput.value, passwordInput.value, emailInput.value)
            return
          } else {
            that.passwordsNotIdentical()
            return
          }
        } else {
          that.notEmail()
          return
        }
      } else {
        that.checkBoxNotChecked()
        return
      }
    }
    return
  }

  signIn() {
    that.removeAll()
    var loginInput = document.getElementById("lusername")
    var passwordInput = document.getElementById("lpassword")
    window.localStorage.setItem("tried", "login")
    if (loginInput && passwordInput) {
      if (passwordInput.value === '' || loginInput.value === '') {
        that.fillForm()
        return
      }
      that.props.login(loginInput.value, passwordInput.value)
    }
    return
  }

  forgotPassword() {
    var el = document.getElementById('lost-password')
    if (el) {
      if (el.value.trim() !== '') {
        window.localStorage.setItem("tried", "reset")
        return that.props.reset(el.value)
      }
    }

    that.setState({ message: 'Username or Email field cannot be empty!' })
    return
  }

  checkResetPasswordEmailStatus() {
    if (window.localStorage.getItem("tried") === "reset") {
      if (that.props.passwordResetState.error) {
        switch (that.props.passwordResetState.error) {
          case '401':
            if (that.state.message !== 'Your account has been blocked!') {
              that.setState({ message: 'Your account has been blocked!' })
            }
            break;
          case '404':
            if (that.state.message !== 'Account with this details has not been found!') {
              that.setState({ message: 'Account with this details has not been found!' })
            }
            break;
          case '500':
            if (that.state.message !== 'There was an internal error. Sorry for inconvinience! Please, try again later.') {
              that.setState({ message: 'There was an internal error. Sorry for inconvinience! Please, try again later.' })
            }
            break;
          default:
            if (that.state.message !== 'There was an internal error. Sorry for inconvinience! Please, try again later.') {
              that.setState({ message: 'There was an internal error. Sorry for inconvinience! Please, try again later.' })
            }
            break;
        }
        window.localStorage.removeItem("tried")
        return
      }

      if (that.props.passwordResetState.status === 'Email sent') {
        if (that.state.message !== 'Email was sent. If you do not see it, please check your spam tab.') {
          window.localStorage.removeItem("tried")
          that.setState({ message: 'Email was sent. If you do not see it, please check your spam tab.' })
        }
        return
      }
    }
  }

  render() {
    return (
      <div className='mx-3'>
        {this.checkResetPasswordEmailStatus()}
        <div className='prompts fixed-prompts mx-auto my-4'>
          <div id="success" className='success w-100'>{this.state.message}</div>
          <div id="error" className='error w-100'></div>
        </div>
        <p id="msg-not-logged" className='text-center'>
          You are not logged in. To host or join the grid please log in or register new account.
        </p>
        <div className="d-md-flex">
          <div id="login" className="center-sign-in login w-50 fix-width-mobile">
            <h2 className='text-center my-3'>Sign in</h2>
            <div className='input-width mx-auto'>
              <label className='labels mt-4'>
                <input id="lusername" className="own-input" placeholder='Username or Email' type="text" />
              </label>
              <label className='labels mt-4'>
                <input id="lpassword" className="own-input" placeholder='Password' type="password" />
              </label>
            </div>
            <div className='text-center my-4'>
              <button className="own-btn btn grey-btn" onClick={this.signIn}>Sign In</button>
            </div>
            <div className='input-width mx-auto'>
              <label className='labels mt-4'>
                <input id="lost-password" className="own-input" placeholder='Username or Email' type="text" />
              </label>
            </div>
            <div className='text-center my-4'>
              <button className="own-btn btn grey-btn" onClick={this.forgotPassword}>Forgot Password</button>
            </div>
          </div>
          {/*<div id="register" className="right register w-50 fix-width-mobile">
            <h2 className='text-center my-3'>Sign up</h2>
            <div className='input-width mx-auto'>
              <label className='labels  mt-4'>
                <input id="remail" className="own-input" placeholder="Email" type="text" />
              </label>
              <label className='labels mt-4'>
                <input id="rusername" className="own-input" placeholder="Username" type="text" />
              </label>
              <label className='labels mt-4'>
                <input id="rpassword" className="own-input" placeholder="Password" type="password" />
              </label>
              <label className='labels mt-4'>
                <input id="rpassword-repeat" className="own-input" placeholder="Repeat Password" type="password" />
              </label>
              <label className='labels mt-4 d-flex align-items-start'>
                <input id="checkbox" type="checkbox" required />
                <p className='writings text-left'>I read and agree with Privacy Policy and Terms and Conditions</p>
              </label>
            </div>
            <div className='text-center my-4'>
              <button className="own-btn btn grey-btn" onClick={this.signUp}>Sign Up</button>
            </div>
          </div>*/}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sign)
