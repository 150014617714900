import React, {
  Component
} from 'react'

import {
  mapStateToProps,
  mapDispatchToProps
} from '../functions/mapStateToProps'

import { connect } from 'react-redux'
import { socket } from './App'
import { Redirect } from "react-router-dom"
import { displayMessage, hideMessage } from '../functions/utils'

var that, justEntered

class Join extends Component {
  constructor(props) {
    super(props)
    this.state = {
      jsx: <div></div>
    }
    that = this
  }

  componentDidMount() {
    justEntered = true
    socket.emit('joinRoom', 'join')
  }

  componentWillUnmount() {
    hideMessage()
    socket.emit('leaveRoom', 'join')
  }

  joinGameByUUID() {
    hideMessage()
    var el = document.getElementById('UUID')
    var pass = document.getElementById('pass')
    if (el && pass) {
      justEntered = false
      that.props.join(
        window.localStorage.getItem("username"),
        window.localStorage.getItem("token"),
        window.localStorage.getItem("refreshToken"),
        el.value,
        pass.value)
    }
  }

  joinRedirection() {
    hideMessage()
    if (that.props.game.error) {
      displayMessage(that.props.game.error)
      return
    }
    if (that.props.game.UUID && !justEntered) {
      justEntered = true
      socket.emit('joinedGame', that.props.game.UUID)
      return <Redirect to={'/game/' + that.props.game.UUID} />
    }
  }

  render() {
    return (
      <div>
        {that.joinRedirection()}
        <div id="success" className='success max-width-400 mx-auto'></div>
        <div className='text-center'>
          <p className='w-50 mx-auto fix-width-mobile'>
            You are logged in. <br />
            We advise you to use PC or laptop to optimize your experience with this app.
          </p>
          <p className='w-50 mx-auto fix-width-mobile'>
            Here you can join or rejoin grid as a player passing grid UUID and password to inputs.
          </p>
          <div className='input-width mx-auto mb-3'>
            <input id="UUID" className='own-input' onfocus="this.removeAttribute('readonly');" placeholder='Game ID' autoComplete='off' />
          </div>
          <div className='input-width mx-auto mb-3'>
            <input id="pass" className='own-input' onfocus="this.removeAttribute('readonly');" placeholder='Game Password' type="password" autoComplete='off' />
          </div>
          <button className="own-btn btn grey-btn" onClick={that.joinGameByUUID}>Join</button>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Join)
